import { useCallback, useRef, useState, useEffect, useContext } from "react";
import { TreeView } from "devextreme-react";
import CheckBox from "devextreme/ui/check_box";
import RadioGroup from "devextreme/ui/radio_group";
import { ItemClickEvent, Node, SelectionChangedEvent } from "devextreme/ui/tree_view";
import { Backdrop, Button, CircularProgress } from "@mui/material";

import { FilesInspection, MoveIManageFiles } from "../../api-client-nswag/taxportal-client";
import { ModalCopyFiles } from "../../cui/components/ModalCopyFiles/ModalCopyFiles";
import { Tree } from "../../cui/components/TreeView/TreeView";
import { SnackbarMessage } from "../../hooks/useSnackbar.types";
import { useContainerDimensions, useSnackbar } from "../../hooks";
import { taxPortalClientApi } from "../../api-client-nswag/taxportal-client-runtime";
import { IManageComponentProps, IManageFilesVars, iManageProps, taxPortalProps } from "./IManageFilesTypes";
import { OidcIdentityContext } from "@rsmus/react-auth";
import "./IManageFiles.scss";
import { Typography, Divider, Box } from "@mui/material";
import { IMANAGEFILE_TITLE } from "../../constants";

type TreeViewRefType = TreeView;

export default function IManageFiles({
  onItemExpanded,
  showMoreLabel,
  filesToMove,
  foldersToShow,
  siteID,
}: IManageComponentProps) {
  const componentRef = useRef<HTMLDivElement | null>(null);
  const { width } = useContainerDimensions(componentRef);
  const { showSnackbar } = useSnackbar();
  const { user } = useContext(OidcIdentityContext);
  const iManageTreeRef = useRef<TreeViewRefType>(null);
  const taxPortalTreeRef = useRef<TreeViewRefType>(null);
  const [isDisplayOverlay, setIsDisplayOverlay] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<Node<any>[]>([]);
  const [selectedFolder, setSelectedFolder] = useState<Node<any>[]>([]);
  const [selectedFoldersIManage, setSelectedFoldersIManage] = useState<Node<any>[]>([]);
  const [filesInspection, setFilesInspection] = useState<FilesInspection>({
    all: [],
    duplicated: [],
  });
  const [itemEdited, setItemEdited] = useState<Array<number>>([]);
  const [isDisplayModal, setIsDisplayModal] = useState(false);
  const [isBlurEnabled, setIsBlurEnabled] = useState(false);
  const arePairSelected = !selectedFiles.length || !selectedFolder.length;
  const isOneSelected = !!selectedFoldersIManage.length || !!selectedFolder.length;

  const abortController = new AbortController();

  const onItemCollapsed = (e: ItemClickEvent<any>) => {
    showMoreLabel(e);
    e.component.beginUpdate();
    const collapse = (current: any) => {
      if (current.expanded) {
        e.component.collapseItem(current.id);
      }
      for (var i = 0; i < current.items.length; i++) {
        collapse(current.items[i]);
      }
    };
    if (e.itemData!.items) {
      e.itemData!.items.forEach((node) => collapse(node));
    }
    e.component.endUpdate();
  };

  const handleChangedFiles = (e: SelectionChangedEvent<any>) => {
    setSelectedFiles(e.component.getSelectedNodes().filter((item: Node<any>) => item.itemData!.itemType === "file"));
    setSelectedFoldersIManage(e.component.getSelectedNodes());
  };

  const handleChangedFolder = (e: SelectionChangedEvent<any>) => {
    setSelectedFolder(e.component.getSelectedNodes());
  };

  const filesDuplicated = useCallback(
    (isFileDuplicated: boolean): MoveIManageFiles[] =>
      filesInspection?.all.filter((file) => file?.isDuplicated === isFileDuplicated),
    [filesInspection.all]
  );

  const moveFilesService = useCallback(
    async (data: MoveIManageFiles[]) => {
      const filesToMoveCleaned = data.map(({ isEdited, isOmitted, ...rest }) => rest);

      const moveFilesRequest = {
        data: filesToMoveCleaned,
        siteId: siteID,
        userEmail: user?.profile.preferred_username,
      };

      showSnackbar({ message: SnackbarMessage.MOVE_IMANAGE_FILES });
      try {
        setIsDisplayOverlay(true);
        const response = await taxPortalClientApi.taxPortal_MoveIManageFilesToTaxPortal(
          moveFilesRequest,
          abortController.signal
        );
        const { message, success } = response;
        unselectAll();
        showSnackbar({ message, severity: success ? "success" : "info" });
      } catch (error: any) {
        setIsDisplayOverlay(true);

        if (error.status === IManageFilesVars.CONFLICT_ERROR) {
          const { response } = error;
          showSnackbar({ message: response.message, severity: "error" });
          setFilesInspection({
            ...filesInspection,
            all: response.filesForInspection,
          });
          setIsDisplayModal(true);
        } else {
          showSnackbar({ message: SnackbarMessage.ERROR, severity: "error" });
        }
      } finally {
        setIsDisplayOverlay(false);
      }
    },
    [filesInspection, siteID]
  );

  const unselectAll = () => {
    iManageTreeRef.current!.instance.unselectAll();
    taxPortalTreeRef.current!.instance.unselectAll();
  };

  const handleCopyRequest = async () => {
    const data = selectedFiles.map((item: Node<any>): MoveIManageFiles => {
      return {
        sourceDocumentId: item.itemData!.id as string,
        sourceContainerId: item.parent!.itemData!.id as string,
        targetParentFolderId: selectedFolder[0].itemData!.id as string,
        fileName: item.itemData!.text as string,
        documentId: 0,
      };
    });

    moveFilesService(data);
  };

  const handleCloseModal = () => {
    setItemEdited([]);
    setIsDisplayModal(false);
    setIsBlurEnabled(false);
  };

  const renderChecksEnabledOnFilesOnly = (e: any) => {
    const checkBoxEl = e.itemElement.parentElement.querySelector(".dx-checkbox");
    const checkBox = CheckBox.getInstance(checkBoxEl);
    checkBox.option("iconSize", 14);

    if (e.itemData!.itemType === "folder") {
      checkBox.option("disabled", true);
      checkBoxEl.style.background = "#ddd";
    }
  };

  const renderRadioButtons = (e: any) => {
    const checkBoxEl = e.itemElement.parentElement.querySelector(".dx-checkbox");
    const checkBox = CheckBox.getInstance(checkBoxEl);

    const radioGroupEl = document.createElement("DIV");
    e.itemElement.parentElement.prepend(radioGroupEl);
    const radioGroup = new RadioGroup(radioGroupEl, {
      items: [{ value: true }],
      valueExpr: "value",
      value: checkBox.option("value"),
      onValueChanged(args: any) {
        checkBox.option("value", args.value);
      },
    });

    const defaultHandler: any = checkBox.option("onValueChanged");
    checkBox.option("onValueChanged", (args: any) => {
      radioGroup.option("value", args.value);
      defaultHandler(args);
    });
    checkBox.option("visible", false);
  };

  const renderTreeViewItem = (item: any) => {
    if (item.text === "Show More" || item.text === "Hide") {
      return (
        <span>
          <b>{item.text}</b>
        </span>
      );
    } else {
      return (
        <>
          <i className={`dx-icon dx-icon-${item.icon}`}></i>
          <span>{item.text}</span>
        </>
      );
    }
  };

  iManageProps.noDataText = filesToMove.pending ? "Loading data..." : "No data to display";
  iManageProps.items = filesToMove.data;
  iManageProps.selectedCounter = String(selectedFiles.length);
  iManageProps.onSelectionChanged = handleChangedFiles;
  iManageProps.onItemRendered = renderChecksEnabledOnFilesOnly;
  iManageProps.onItemExpanded = onItemExpanded;
  iManageProps.onItemCollapsed = onItemCollapsed;
  iManageProps.itemRender = renderTreeViewItem;
  iManageProps.width = width - 10;

  taxPortalProps.noDataText = foldersToShow.pending ? "Loading data..." : "No data to display";
  taxPortalProps.items = foldersToShow.data;
  taxPortalProps.onSelectionChanged = handleChangedFolder;
  taxPortalProps.onItemRendered = renderRadioButtons;
  taxPortalProps.onItemCollapsed = onItemCollapsed;
  taxPortalProps.width = width - 10;

  useEffect(() => {
    setFilesInspection({
      all: filesInspection.all,
      duplicated: filesDuplicated(true),
      copy: filesDuplicated(false),
      initial: filesDuplicated(true),
    });
  }, [filesDuplicated]);

  const props = {
    filesInspection,
    isDisplayModal,
    setFilesInspection,
    setIsDisplayModal,
    itemEdited,
    setItemEdited,
    onHandleService: moveFilesService,
    handleCloseModal,
    isBlurEnabled,
    setIsBlurEnabled,
  };

  return (
    <>
      <div>
        <Box sx={{ paddingLeft: 2 }}>
          <Typography variant="h6" component="h1" gutterBottom sx={{ fontSize: "15px !important", paddingTop: 2 }}>
            {IMANAGEFILE_TITLE}
          </Typography>
          <Divider sx={{ background: "#099cde" }} />
        </Box>
      </div>
      <div className="hq-page">
        <div className="hq-imanage-files">
          <div className="hq-tree" ref={componentRef}>
            <Tree {...iManageProps} reference={iManageTreeRef} />
          </div>
          <div className="hq-tree">
            <Tree {...taxPortalProps} reference={taxPortalTreeRef} />
          </div>
        </div>
        <Button
          onClick={handleCopyRequest}
          disabled={arePairSelected}
          variant="outlined"
          className="hq_button button-right"
        >
          Copy
        </Button>
        <Button onClick={unselectAll} disabled={!isOneSelected} variant="outlined" className="hq_button button-right">
          Clear selection
        </Button>
      </div>
      <ModalCopyFiles {...props} />
      <Backdrop open={isDisplayOverlay}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
